import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Dimensions} from "../../../globalModels/Dimensions";
import {Files} from "@/components/globalModels/Files";

export function CalculatorEngraving() {

    this.checkValid = checkValid

    this.data = {
        differentEngrave: false,
        adjustingLayout: false,
        count: '',
        customization: null,
        Dimensions: new Dimensions(),

        id: null,
        isEngraving: false,
        // engravingLength: 0,
        // engravingWidth: 0,
        // engravingCount: 0,
        // engravingCustomization: null,
        // engravingTime: 0,
        engravingPrice: 0,
        engravingAdminDownloaded: false,
        EngravingFile: new Files({
            maxCountFiles: 5,
            maxSizeFiles: 3
        }),
        comment: '',
    }

    this.validation = {
        count: false,
    }

    this.validationTranslate = {
        count: '',
    }

    this.validationTxt = {
        count: false,
    }

    /**
     * Getters
     */
    this.getCount = () => {
        return this.data.count
    }
    this.getDifferentEngrave = () => {
        return this.data.differentEngrave
    }
    this.getAdjustingLayout = () => {
        return this.data.adjustingLayout
    }
    this.getCustomization = () => {
        return this.data.customization
    }

    /**
     * Setters
     */
    this.setCount = (val) => {
        this.data.count = val
    }
    this.setDifferentEngrave = (val) => {
        this.data.differentEngrave = val
    }
    this.setAdjustingLayout = (val) => {
        this.data.adjustingLayout = val
    }
    this.setCustomization = (val) => {
        this.data.customization = val
    }
}

/**
 * Functions
 */

CalculatorEngraving.prototype.dataValidation = function() {
    let validationItems = {
        count: this.getCount(),
    }

    let validationOptions = {
        count: {type: ['numeric', 'empty']},
    }

    let dimensionsValidation = this.data.Dimensions.dimensionsPartialValidate({
        name: false,
        weight: false,
        height: false,
        width: true,
        dimensionLength: true,
        widthLB: false,
        widthOZ: false,
        weightLB: false,
        weightOZ: false,
    })
    return (this.checkValid(validationItems, validationOptions) && dimensionsValidation)
}

CalculatorEngraving.prototype.dataFBMEngravingValidation = function() {
    let validationItems = {
        count: this.getCount(),
    }

    let validationOptions = {
        count: {type: ['numeric', 'empty']},
    }

    let dimensionsValidation = this.data.Dimensions.dimensionsPartialValidate({
        name: false,
        weight: false,
        height: false,
        width: true,
        dimensionLength: true,
        widthLB: false,
        widthOZ: false,
        weightLB: false,
        weightOZ: false,
    })
    return (this.checkValid(validationItems, validationOptions) && dimensionsValidation)
}


/**
 * Prepare Data
 */

CalculatorEngraving.prototype.prepareData = function() {
    return {
        'offer': this.getCustomization() ? this.getCustomization()?.id : null,
        'length': this.data.Dimensions.getDimensionsLength(),
        'width': this.data.Dimensions.getDimensionsWidth(),
        'count': this.getCount(),
        'need_different_engrave': this.getDifferentEngrave(),
        'need_adjusting_layout': this.getAdjustingLayout(),
    }
}