import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Files} from "@/components/globalModels/Files";

export function TShirtPrinting() {

    this.checkValid = checkValid

    this.data = {
        isTShirtPrinting: false,
        quantity: '',
        color: null,
        quality: null,
        File: new Files({
            maxCountFiles: 6,
            maxSizeFiles: 3
        }),
        size: null,
        resultCost: 0,
        customization: null,
        comment: '',
    }

    this.validation = {
        quantity: false,
        color: false,
        quality: false,
    }

    this.validationTranslate = {
        quantity: '',
        color: '',
        quality: '',
    }

    this.validationTxt = {
        quantity: false,
        color: false,
        quality: false,
    }

    /**
     * Getters
     */
    this.getQuantity = () => {
        return this.data.quantity
    }
    this.getColor = () => {
        return this.data.color
    }
    this.getQuality = () => {
        return this.data.quality
    }
    this.getCustomization = () => {
        return this.data.customization
    }
    this.getSize = () => {
        return this.data.size
    }

    /**
     * Setters
     */
    this.setQuantity = (val) => {
        this.data.quantity = val
    }
    this.setColor = (val) => {
        this.data.color = val
    }
    this.setQuality = (val) => {
        this.data.quality = val
    }
    this.setCustomization = (val) => {
        this.data.customization = val
    }
    this.setSize = (val) => {
        this.data.size = val
    }
}

/**
 * Functions
 */

TShirtPrinting.prototype.dataValidation = function() {
    let validationItems = {
        quantity: this.getQuantity(),
        color: this.getColor(),
        quality: this.getQuality(),
    }

    let validationOptions = {
        quantity: {type: ['numeric', 'empty']},
        // color: {type: ['empty']},
        // quality: {type: ['empty']},
    }

    return this.checkValid(validationItems, validationOptions)
}


/**
 * Prepare Data
 */

TShirtPrinting.prototype.prepareData = function() {
    return {
        'offer': this.getCustomization() ? this.getCustomization()?.id : null,
        'quantity': this.getQuantity(),
        'size': this.getSize(),
        // 'colorType': this.getColor(),
        // 'type': this.getQuality(),
    }
}