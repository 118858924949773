import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Files} from "@/components/globalModels/Files";

export function Embroidery() {

    this.checkValid = checkValid

    this.data = {
        isEmbroidery: false,
        stitches: '',
        quantity: '',
        File: new Files({
            maxCountFiles: 5,
            maxSizeFiles: 3
        }),
        resultCost: 0,
        customization: null,
        comment: '',
    }

    this.validation = {
        stitches: false,
        quantity: false,
    }

    this.validationTranslate = {
        stitches: '',
        quantity: '',
    }

    this.validationTxt = {
        stitches: false,
        quantity: false,
    }

    /**
     * Getters
     */
    this.getStitches = () => {
        return this.data.stitches
    }
    this.getQuantity = () => {
        return this.data.quantity
    }
    this.getCustomization = () => {
        return this.data.customization
    }

    /**
     * Setters
     */
    this.setStitches = (val) => {
        this.data.stitches = val
    }
    this.setQuantity = (val) => {
        this.data.quantity = val
    }
    this.setDifferentEngrave = (val) => {
        this.data.differentEngrave = val
    }
    this.setCustomization = (val) => {
        this.data.customization = val
    }
}

/**
 * Functions
 */

Embroidery.prototype.dataValidation = function() {
    let validationItems = {
        stitches: this.getStitches(),
        quantity: this.getQuantity(),
    }

    let validationOptions = {
        stitches: {type: ['numeric', 'empty']},
        quantity: {type: ['numeric', 'empty']},
    }

    return this.checkValid(validationItems, validationOptions)
}


/**
 * Prepare Data
 */

Embroidery.prototype.prepareData = function() {
    return {
        'offer': this.getCustomization() ? this.getCustomization()?.id : null,
        'quantity': this.getQuantity(),
        'numberOfStitches': this.getStitches(),
    }
}