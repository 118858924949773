import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Files} from "@/components/globalModels/Files";

export function LeatherStamp() {

    this.checkValid = checkValid

    this.data = {
        isLeatherStamp: false,
        stampingLine: '',
        File: new Files({
            maxCountFiles: 1,
            maxSizeFiles: 3
        }),
        resultCost: '',
        customization: null,
        comment: '',
    }

    this.validation = {
        stampingLine: false,
    }

    this.validationTranslate = {
        stampingLine: '',
    }

    this.validationTxt = {
        stampingLine: false,
    }

    /**
     * Getters
     */
    this.getStampingLine = () => {
        return this.data.stampingLine
    }
    this.getCustomization = () => {
        return this.data.customization
    }

    /**
     * Setters
     */
    this.setStampingLine = (val) => {
        this.data.stampingLine = val
    }
    this.setDifferentEngrave = (val) => {
        this.data.differentEngrave = val
    }
    this.setCustomization = (val) => {
        this.data.customization = val
    }
}

/**
 * Functions
 */

LeatherStamp.prototype.dataValidation = function() {
    let validationItems = {
        stampingLine: this.getStampingLine(),
    }

    let validationOptions = {
        stampingLine: {type: ['empty']},
    }

    return this.checkValid(validationItems, validationOptions)
}


/**
 * Prepare Data
 */

LeatherStamp.prototype.prepareData = function() {
    return {
        'offer': this.getCustomization() ? this.getCustomization()?.id : null,
        'stamping_line': this.getStampingLine(),
    }
}