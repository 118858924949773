import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Files} from "@/components/globalModels/Files";

export function Sticker() {

    this.checkValid = checkValid

    this.data = {
        isSticker: false,
        count: '',
        File: new Files({
            maxCountFiles: 5,
            maxSizeFiles: 3
        }),
        resultCost: '',
        customization: null,
        stickerType: 'glossy',
        stickerKissCut: true,
        stickerPerfCut: false,
        comment: '',
    }

    this.validation = {
        count: false,
        stickerKissCut: false,
        stickerPerfCut: false,
    }

    this.validationTranslate = {
        count: '',
        stickerKissCut: '',
        stickerPerfCut: '',
    }

    this.validationTxt = {
        count: false,
        stickerKissCut: false,
        stickerPerfCut: false,
    }

    /**
     * Getters
     */
    this.getCount = () => {
        return this.data.count
    }
    this.getCustomization = () => {
        return this.data.customization
    }
    this.getSize = () => {
        return this.data.size
    }

    /**
     * Setters
     */
    this.setCount = (val) => {
        this.data.count = val
    }
    this.setCustomization = (val) => {
        this.data.customization = val
    }
    this.setSize = (val) => {
        this.data.size = val
    }
}

/**
 * Functions
 */

Sticker.prototype.dataValidation = function() {
    let validationItems = {
        count: this.getCount(),
    }

    let validationOptions = {
        count: {type: ['numeric', 'empty']},
    }

    let valid = true
    if(!this.data.stickerKissCut && !this.data.stickerPerfCut) {
        this.validation.stickerKissCut = true
        this.validation.stickerPerfCut = true
        this.validationTranslate.stickerKissCut = 'error_stickerCheckbox'
        this.validationTranslate.stickerPerfCut = 'error_stickerCheckbox'
        valid = false
    } else {
        this.validation.stickerKissCut = false
        this.validation.stickerPerfCut = false
        this.validationTranslate.stickerKissCut = ''
        this.validationTranslate.stickerPerfCut = ''
    }

    return this.checkValid(validationItems, validationOptions) && valid
}


/**
 * Prepare Data
 */

Sticker.prototype.prepareData = function() {
    let cuttingType = []
    if(this.data.stickerKissCut) cuttingType.push('kiss_cut')
    if(this.data.stickerPerfCut) cuttingType.push('perf_cut')

    return {
        'material_type': this.data.stickerType,
        'quantity': this.getCount(),
        'cutting_type': cuttingType
    }
}