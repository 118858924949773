import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Dimensions} from "../../../globalModels/Dimensions";
import {Files} from "@/components/globalModels/Files";

export function UVPrinting() {

  this.checkValid = checkValid

  this.data = {
    isUVPrinting: false,
    count: '',
    customization: null,
    File: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 3
    }),
    Dimensions: new Dimensions(),
    resultCost: '',
    comment: '',
  }

  this.validation = {
    count: false,
  }

  this.validationTranslate = {
    count: '',
  }

  this.validationTxt = {
    count: false,
  }

  /**
   * Getters
   */
  this.getCount = () => {
    return this.data.count
  }
  this.getCustomization = () => {
    return this.data.customization
  }

  /**
   * Setters
   */
  this.setCount = (val) => {
    this.data.count = val
  }
  this.setCustomization = (val) => {
    this.data.customization = val
  }
}

/**
 * Functions
 */

UVPrinting.prototype.dataValidation = function() {
  let validationItems = {
    count: this.getCount(),
  }

  let validationOptions = {
    count: {type: ['numeric', 'empty']},
  }

  let dimensionsValidation = this.data.Dimensions.dimensionsPartialValidate({
    name: false,
    weight: false,
    height: false,
    width: true,
    dimensionLength: true,
    widthLB: false,
    widthOZ: false,
    weightLB: false,
    weightOZ: false,
  })
  return (this.checkValid(validationItems, validationOptions) && dimensionsValidation)
}


/**
 * Prepare Data
 */

UVPrinting.prototype.prepareData = function() {
  return {
    'offer': this.getCustomization() ? this.getCustomization()?.id : null,
    'length': this.data.Dimensions.getDimensionsLength(),
    'width': this.data.Dimensions.getDimensionsWidth(),
    'quantity': this.getCount(),
  }
}